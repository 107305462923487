import Network from '@/services/network'

export default function handleProadvisor() {
    const network = new Network;

    const fetchSummery = () => {
      return network.api('get', '/proadvisor/summery');
    }
    const fetchCertificate = () => {
      return network.api('get', '/proadvisor/certificate');
    }
    const fetchBusinessList = () => {
      return network.api('get', '/proadvisor/register-business');
    }

    const fetchCompanyList = () => {
      return network.api('get', '/proadvisor/active-company');
    }

    const storeCertificate = (data) => {
      return network.api('post', '/proadvisor/certificate', data);
    }

    const storeBusiness = (data) => {
      return network.api('post', '/proadvisor/register-business', data);
    }

    return {
        fetchSummery,
        storeCertificate,
        storeBusiness,
        fetchCertificate,
        fetchBusinessList,
        fetchCompanyList
    }
}