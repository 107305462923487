<template>
    <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle" style="display: block; background: #1c1d1c33">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">New Business Registration</h1>
                            <button
                                @click="toggleModal"
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                </div>
                <hr class="mt-0">
                
                <div class="pb-2">
                    <!-- form -->
                    <vForm @submit="submit" :validation-schema="schema">
                        <div class="row gy-2 px-2">
                            <div class="col-12">
                                <label for="formFile" class="form-label">Select owner photo</label>
                                <input @change="onUploadOwnerPhoto" class="form-control" type="file" id="formFile">
                            </div>

                            <div class="col-12">
                                <label for="formFile" class="form-label">Business Photo (Selfie by Proadvisor)</label>
                                <input @change="onUploadBusinessPhoto" class="form-control" type="file" id="formFile">
                            </div>

                            <div class="col-12">
                                <label class="form-label" for="chartName">Business Name</label>
                                <div class="input-group">
                                    <vField
                                        v-model="formData.business_name"
                                        id="chartName"
                                        name="business_name"
                                        class="form-control add-credit-card-mask" 
                                        type="text"
                                        placeholder="Name"
                                    />
                                    <ErrorMessage name="business_name" class="invalid-feedback d-block qcont"/>
                                </div>
                            </div>
                            <div class="col-12">
                                <label class="form-label" for="chartName">Owner Name</label>
                                <div class="input-group">
                                    <vField
                                        v-model="formData.owner_name"
                                        id="chartName"
                                        name="owner_name"
                                        class="form-control add-credit-card-mask" 
                                        type="text"
                                        placeholder="Name"
                                    />
                                    <ErrorMessage name="owner_name" class="invalid-feedback d-block qcont"/>
                                </div>
                            </div>
                            <div class="col-12">
                                <label class="form-label" for="chartName">Owner Mobile</label>
                                <div class="input-group">
                                    <vField
                                        v-model="formData.owner_phone"
                                        id="chartName"
                                        name="owner_phone"
                                        class="form-control add-credit-card-mask" 
                                        type="text"
                                        placeholder="Name"
                                    />
                                    <ErrorMessage name="owner_phone" class="invalid-feedback d-block qcont"/>
                                </div>
                            </div>
                            <div class="col-12">
                                <label class="form-label" for="chartName">Owner Email</label>
                                <div class="input-group">
                                    <vField
                                        v-model="formData.owner_email"
                                        id="chartName"
                                        name="owner_email"
                                        class="form-control add-credit-card-mask" 
                                        type="text"
                                        placeholder="Name"
                                    />
                                    <ErrorMessage name="owner_email" class="invalid-feedback d-block qcont"/>
                                </div>
                            </div>
                            <div class="col-12">
                                <label class="form-label" for="chartName">Owner NID / Business ID</label>
                                <div class="input-group">
                                    <vField
                                        v-model="formData.owner_nid"
                                        id="chartName"
                                        name="owner_nid"
                                        class="form-control add-credit-card-mask" 
                                        type="text"
                                        placeholder="Name"
                                    />
                                    <ErrorMessage name="owner_nid" class="invalid-feedback d-block qcont"/>
                                </div>
                            </div>
                            <div class="col-12">
                                <label class="form-label" for="chartName">Business Location</label>
                                <div class="input-group">
                                    <vField
                                        v-model="formData.business_location"
                                        id="chartName"
                                        class="form-control add-credit-card-mask"
                                        name="business_location"
                                        type="text"
                                        placeholder="Name"
                                    />
                                    <ErrorMessage name="business_location" class="invalid-feedback d-block qcont"/>
                                </div>
                            </div>
                            <div class="col-12">
                                <label class="form-label" for="chartName">Business Type</label>
                                <div class="input-group">
                                    <vField
                                        v-model="formData.business_type"
                                        id="chartName"
                                        name="business_type"
                                        class="form-control add-credit-card-mask" 
                                        type="text"
                                        placeholder="Name"
                                    />
                                    <ErrorMessage name="business_type" class="invalid-feedback d-block qcont"/>
                                </div>
                            </div>
                            <div class="col-12">
                                <label class="form-label" for="chartName">Business Full Address</label>
                                <div class="input-group">
                                    <vField
                                        v-model="formData.business_address"
                                        id="chartName"
                                        name="business_address"
                                        class="form-control add-credit-card-mask" 
                                        type="text"
                                        placeholder="Name"
                                    />
                                    <ErrorMessage name="business_address" class="invalid-feedback d-block qcont"/>
                                </div>
                            </div>

                           
                        </div>

                        <div class="col-12 text-center">
                            <hr class="mt-2">
                            <button type="submit" class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light">
                                <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                Submit
                            </button>
                            <button @click.prevent="toggleModal" class="btn btn-outline-secondary mt-1 waves-effect">
                                Cancel
                            </button>
                        </div>
                    </vForm>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BusinessAddModal',

    data: () => ({
        isOpen: false,
        schema: {
            business_name: 'required',
            owner_phone: 'required',
            owner_email: 'required|email',
            owner_name: 'required',
            owner_nid: 'required|numeric',
            business_location: 'required',
            business_type: 'required', 
            business_address: 'required'
        }
    }),


    methods: {
        submit() {
            this.$emit('onClickSubmitBusiness')
        },
        toggleModal () {
            this.isOpen = !this.isOpen
        },
        onUploadOwnerPhoto(event) {
            this.formData.owner_photo = event.target.files[0]
        },
        onUploadBusinessPhoto(event) {
            this.formData.business_photo = event.target.files[0]
        }
    },

    props: {
        loading: Boolean,
        formData: Object
    }
}
</script>