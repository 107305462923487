<template>
    <div class="mt-2" style="min-height: 200px">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Certificate Name</th>
                        <th>Enroll Date</th>
                        <th>Score</th>
                        <th>Completion Date</th>
                        <th>Current Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(certificate, i) in certificates" :key="i">
                        <tr>
                            <td>{{ certificate.certificate_name }}</td>
                            <td>{{ certificate.enroll_date }}</td>
                            <td>{{ certificate.score }}</td>
                            <td>{{ certificate.completion_date }}</td>
                            <td>{{ certificate.status }}</td>
                            <td>View</td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

export default {
    name: 'CertificateTable',
    
    props: {
        certificates: Array
    }
}
</script>
