<template>
    <div style="min-height: calc(100vh - 158px);">
        <div class="row">
            <div class="col-md-12 col-xl-8 mx-auto">
                <div class="card p-2">
                    <TitleButton
                        :showBtn="false"
                        title="ProAdvisor summery"
                    />
                    <hr>
                    <div v-if="loading" class="d-flex justify-content-between flex-wrap">
                        <BoxLoader />
                        <BoxLoader />
                    </div>
                    <template v-else>
                        
                        
                        <div class="d-flex flex-wrap mt-2">
                            <div>
                                <img
                                    class="img-fluid img-thumbnail trophy width-200 height-200"
                                    src="/app-assets/images/trophy.png" 
                                    alt=""
                                >
                            </div>
                            <div class="flex-grow-1 px-2 summery">
                                <h2>{{ summery.user?.name }}</h2>
                                <h4>{{  summery.proAdvisorDetails?.tier?.name }} ({{ summery.proAdvisorDetails?.total_points }})</h4>
                                <h5>Certificate : {{  summery.certificateCount }}</h5>
                                <h5>Business Registration : {{  summery.businessRegistrationCount }}</h5>
                                <h5>Active miaccounts client subscription: {{  summery.activeClientSubscription }}</h5>
                            </div>
                            <div class="">
                                <img
                                    class="img-fluid  img-thumbnail width-200 height-200"
                                    :src="summery.user?.photo" 
                                    alt=""
                                >
                            </div>
                        </div>
                    </template>
                </div>

                <div class="card p-2">
                    <!--start ProAdvisor Certification -->
                    <TitleButton
                        btnTitle="Add New"
                        :showAddNew="true"
                        :showBtn="false"
                        title="ProAdvisor Certificate"
                        @onClickAddNewButton="openCertificateAddModal"

                    />
                    <!--end ProAdvisor Certification -->
                    <hr>
                    <!--start ProAdvisor Certification table -->
                    <CertificateTable :certificates="certificates"/>
                    <!--end ProAdvisor Certification table -->
                </div>

                <div class="card p-2">
                    <!--start ProAdvisor Certification -->
                    <TitleButton
                        btnTitle="Add New"
                        :showAddNew="true"
                        :showBtn="false"
                        title="Business Registration"
                        @onClickAddNewButton="openBusinessAddModal"

                    />
                    <hr>
                    <div class="row mt-2">
                        <div v-for="(business, i) in business" :key="i" class="cols-12 col-sm-6 col-lg-3">
                            <div class="card ecommerce-card">
                                <div class="item-img text-center">
                                    <!-- <a href="app-ecommerce-details.html"> -->
                                        <img 
                                            class="img-fluid card-img-top" 
                                            :src="business.business_photo_url" alt="img-placeholder">
                                    <!-- </a> -->
                                </div>
                                <div class="p-1">
                                    <h4 class="item-name">
                                        {{ business.business_name }}
                                    </h4>
                                    <p class="card-text item-description">
                                        {{ business.business_location }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end ProAdvisor Certification -->
                </div>

                <div class="card p-2">
                    <!--start ProAdvisor Certification -->
                    <TitleButton
                        btnTitle="Add New"
                        :showAddNew="false"
                        :showBtn="false"
                        title="Active miaccounts clients"
                    />
                    <!--end ProAdvisor Certification -->
                    <hr>
                    <div class="row mt-2">
                        <div v-for="(company, i) in companies" :key="i" class="cols-12 col-sm-6 col-lg-3">
                            <div class="card ecommerce-card">
                                <div class="item-img text-center">
                                    <!-- <a href="app-ecommerce-details.html"> -->
                                        <img 
                                            class="img-fluid card-img-top" 
                                            :src="company.logo" alt="img-placeholder">
                                    <!-- </a> -->
                                </div>
                                <div class="p-1">
                                    <h4 class="item-name">
                                        {{ company.name }}
                                    </h4>
                                    <p class="card-text item-description">
                                        {{ company.industry }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Loader v-if="loading"/>
        <CertificateAddModal
            :loading="certificateCreateLoading"
            ref="certificate" 
            :formData="certificateFormData"
            @onClickSubmitCertificate="createCertificate"
        />
        <BusinessAddModal
            :loading="businessCreateLoading"
            ref="business" 
            :formData="businessFormData"
            @onClickSubmitBusiness="createBusiness"
        />
    </div>
</template>


<script>
import handleProadvisor from '@/services/modules/proadvisor'
import { inject } from 'vue'
import Loader from '@/components/atom/LoaderComponent'
import TitleButton from '@/components/atom/TitleButton'
import CertificateTable from '@/components/molecule/proadvisor/CertificateTable'
import CertificateAddModal from '@/components/molecule/proadvisor/CertificateAddModal'
import BusinessAddModal from '@/components/molecule/proadvisor/BusinessAddModal'
import BoxLoader from '@/components/atom/loader/Loader'

export default {
    name: 'Proadvisor',

    components: {
        Loader,
        TitleButton,
        CertificateTable,
        CertificateAddModal,
        BoxLoader,
        BusinessAddModal
    },

    data: () => ({
        loading: false,
        certificateCreateLoading: false,
        businessCreateLoading: false,
        summery: {},
        business: [],
        certificates: [],
        companies: [],
        certificateFormData: {
            enroll_date: '',
            completion_date: '',
            certificate_name: '',
            certificate_file: null
        },
        businessFormData: {
            owner_photo: '',
            business_photo: '',
            business_name: '',
            owner_name: '',
            owner_phone: '',
            owner_email: '',
            owner_nid: '',
            business_location: '',
            business_type: '',
            business_address: '',
        }
    }),

    methods: {
        openCertificateAddModal( ) {
            this.$refs.certificate.toggleModal();
        },
        openBusinessAddModal( ) {
            this.$refs.business.toggleModal();
        },

        resetForm(obj) {
            Object.keys(obj).forEach(key => {
                obj[key] = ''
            })
        },

        prepareFormData (data) {
            let fd = new FormData();
            Object.keys(data).forEach(key => {
                fd.append(key, data[key])
            })
            return fd

        },

        async createBusiness() {
            if(!this.businessFormData.owner_photo || !this.businessFormData.business_photo) {
                this.showError('Select all photo file')
                return
            }
           
            let fd = new this.prepareFormData(this.businessFormData);
            this.businessCreateLoading = true

            try {
                let res = await this.storeBusiness(fd)
                if(!res.status) {
                    this.showError('res.message')
                }
                if(res.status) {
                    this.showSuccess(res.message)
                    this.$refs.business.toggleModal();
                    this.resetForm(this.businessFormData)
                    this.getBusinessList()
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.data.message)
                }
            } finally {
                this.businessCreateLoading = false
            }
        },
        async createCertificate() {
             if(!this.certificateFormData.certificate_file) {
                this.showError('Select certificate pdf file')
                return
            }
            let fd = new this.prepareFormData(this.certificateFormData);
            this.certificateCreateLoading = true

            try {
                let res = await this.storeCertificate(fd)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.showSuccess(res.message)
                    this.$refs.certificate.toggleModal();
                    this.resetForm(this.certificateFormData)
                    this.getCertificate()
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.data.message)
                }
            } finally {
                this.certificateCreateLoading = false
            }
        },
        async getCompanyList() {
            try {
                this.loading = true
                let res = await this.fetchCompanyList()
                if(!res.status) {
                    //this.showError('res.message')
                }
                if(res.status) {
                    this.companies = res.data
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },
        async getSummery() {
            try {
                this.loading = true
                let res = await this.fetchSummery()
                // if(!res.status) {
                //     this.showError('res.message')
                // }
                if(res.status) {
                    this.summery = res.data
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },

        async getBusinessList() {
           // return;
            try {
                this.loading = true
                let res = await this.fetchBusinessList()
                if(!res.status) {
                    //this.showError(res.message)
                }
                if(res.status) {
                    this.business = res.data
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },

        async getCertificate() {
            try {
                this.loading = true
                let res = await this.fetchCertificate()
                if(!res.status) {
                    //this.showError(res.message)
                }
                if(res.status) {
                    this.certificates = res.data
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },
    },

    setup() {
        const showError =  inject('showError');
        const showSuccess =  inject('showSuccess');

        const {
            fetchSummery,
            storeBusiness,
            storeCertificate,
            fetchCertificate,
            fetchBusinessList,
            fetchCompanyList
        } = handleProadvisor()

        return {
            fetchSummery,
            storeBusiness,
            storeCertificate,
            fetchCertificate,
            fetchCompanyList,
            fetchBusinessList,
            showError,
            showSuccess
        }
    },

    mounted() {
        this.getSummery()
        this.getCompanyList()
        this.getBusinessList()
        this.getCertificate()
    }

}
</script>

<style scoped>
@media (max-width: 450px) {
    .summery {
        margin: 20px 0;
        padding-left: 0 !important;
    }
}
@media (max-width: 580px) {
    .trophy {
        height: 100px !important;
        width: 100px !important;
    }
}
</style>
