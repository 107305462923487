<template>
    <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle" style="display: block; background: #1c1d1c33">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">Add ProAdvisor Certification</h1>
                            <button
                                @click="toggleModal"
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                </div>
                <hr class="mt-0">
                
                <div class="pb-2">
                    <!-- form -->
                    <vForm  @submit="submit" :validation-schema="schema">
                        <div class="row gy-2 px-2">
                            <div class="col-12">
                                <label for="formFile" class="form-label">miaccounts ProAdvisor Certification(.pdf)</label>
                                <input @change="onUpload" class="form-control" type="file" id="formFile">
                            </div>

                            <div class="col-12">
                                <label for="formFile" class="form-label">Enroll Date</label>
                                <input type="hidden" class="form-control invoice-edit-input date-picker flatpickr-input" readonly="readonly">
                                <vField
                                    class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                                    tabindex="1"
                                    name="enroll_date"
                                    type="date" 
                                    placeholder=""
                                    @change="onChangeEnrollDate"
                                />
                                 <ErrorMessage name="enroll_date" class="invalid-feedback d-block qcont"/>
                            </div>

                            <div class="col-12">
                                <label class="form-label" for="chartName">Name</label>
                                <div class="input-group input-group-merge">
                                    <vField
                                        v-model="formData.certificate_name"
                                        id="chartName"
                                        name="certificate_name"
                                        class="form-control add-credit-card-mask" 
                                        type="text"
                                        placeholder="Name"
                                    />
                                    <ErrorMessage name="certificate_name" class="invalid-feedback d-block qcont"/>
                                </div>
                            </div>

                            <div class="col-12">
                                <label for="formFile" class="form-label">Completion Date</label>
                                <input type="hidden" class="form-control invoice-edit-input date-picker flatpickr-input" readonly="readonly">
                                <vField
                                    class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                                    tabindex="1"
                                    type="date"
                                    name="completion_date"
                                    placeholder=""
                                    @change="onChangeCompletionDate"
                                />
                                <ErrorMessage name="completion_date" class="invalid-feedback d-block qcont"/>
                            </div>
                        </div>

                        <div class="col-12 text-center">
                            <hr class="mt-2">
                            <button type="submit" class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light">
                                <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                Submit
                            </button>
                            <button @click.prevent="toggleModal" class="btn btn-outline-secondary mt-1 waves-effect">
                                Cancel
                            </button>
                        </div>
                    </vForm>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CertificateAddModal',

    data: () => ({
        isOpen: false,
        schema: {
            certificate_name: 'required',
            enroll_date: 'required',
            completion_date: 'required'
        }
    }),


    methods: {
        submit () {
            this.$emit('onClickSubmitCertificate')
        },
        toggleModal () {
            this.isOpen = !this.isOpen
        },
        onChangeEnrollDate(event) {
           this.formData.enroll_date = event.target.value
        },
        onChangeCompletionDate(event) {
            this.formData.completion_date = event.target.value
        },
        onUpload(event) {
            this.formData.certificate_file = event.target.files[0]
        }
    },

    props: {
        loading: Boolean,
        formData: Object
    }
}
</script>